import React, { useEffect, useState } from "react";
import InputRadio from "../../../../components/InputRadio";
import { getIndexFromObjectArray } from "../../../../helpers/helpers";
import { createOptions } from "../../../../helpers/selects";
import ICMSVenda from "./ICMSVenda";
import ICMSCompra from "./ICMSCompra";

const MultiplePercentage = ({productDestinacao, productDestinoUf, productOrigem, productNcm, param, productParams, setProductParams, productTotalCost, totalSalePrice}) => {
    const [index, setIndex] = useState(null);

    useEffect(() => {
        let toIndex = getIndexFromObjectArray(productParams, 'id', param.id);
        setIndex(toIndex);
    }, [productParams]);

    const handleParamChange = value => {
        setProductParams(prev => {
            let toProductParams = [...prev];
            toProductParams[index].value = value;
            return toProductParams;
        });
    }

    return (
        <>
            {index !== null && param.name !== 'ICMS de Compra' && param.name !== 'ICMS de Venda' &&
                <>
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>{param.name}</h2>
                        </div>
                    </div>
                    <div className="section">
                        <InputRadio 
                            options={createOptions(param.options, 'description', 'value')}
                            value={productParams[index].value}
                            change={handleParamChange}
                        />
                    </div>
                </>
            }
            {index !== null && param.name === 'ICMS de Compra' &&
                <ICMSCompra 
                    productDestinacao={productDestinacao}
                    productDestinoUf={productDestinoUf}
                    productOrigem={productOrigem}
                    productNcm={productNcm}
                    index={index}
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                    productTotalCost={productTotalCost}
                />
            }
            {index !== null && param.name === 'ICMS de Venda' &&
                <ICMSVenda 
                    productDestinacao={productDestinacao}
                    productDestinoUf={productDestinoUf}
                    productOrigem={productOrigem}
                    productNcm={productNcm}
                    index={index}
                    param={param}
                    productParams={productParams}
                    setProductParams={setProductParams}
                    totalSalePrice={totalSalePrice}
                />
            }
        </>
    );
}

export default MultiplePercentage;