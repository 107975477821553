import React, { useState, useEffect, useRef } from "react";

import PanelTemplate from "../../templates/Panel";
import BoxTabs from "../../components/BoxTabs";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import InputEditor from "../../components/InputEditor";
import InputCheckboxes from "../../components/InputCheckboxes";
import PageFooter from "../../components/PageFooter";
import Button from "../../components/Button";
import InputColors from "../../components/InputColors";
import PageHeader from "../../components/PageHeader";
import InputTextarea from "../../components/InputTextarea";
import InputCheckboxesSublevels from "../../components/InputCheckboxesSublevels";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { createOptions } from "../../helpers/selects";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";
import { getBase64, getBase64Promise } from "../../helpers/conversions";
import { ModalDelete } from "../../components/ModalDelete";
import Modal from "../../components/Modal";
import ProductImagesTab from "./tabs/ImagesTab";
import { Loading } from "../../components/Loading";
import { hasPermission } from "../../services/auth";
import { ProductTributacao } from "./tabs/tributacao";

const statusOptions = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
];

const featuredOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
];

const ProductDetailsTab = ({
    loading, 
    status, 
    setStatus, 
    sku, 
    setSku, 
    featured, 
    setFeatured, 
    recent, 
    setRecent, 
    name, 
    setName, 
    categories, 
    setSelectedCategories, 
    selectedCategories, 
    description, 
    setDescription, 
    techDescription, 
    setTechDescription, 
    tags, 
    tag, 
    setTag, 
    minQuantity, 
    setMinQuantity
}) => {
    return (
        <div>
            {!loading &&
            <>
                {/* <div className="row" style={{ marginBottom: 15, marginTop: 15 }}>
                    <div className="col">
                        <a href="#">Abrir no site</a>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-6">
                        <h2 className="section-title">Informações gerais</h2>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Status`} options={statusOptions} value={status} change={setStatus} />
                            </div>
                            <div className="col-6">
                                <Input label={`Código SKU`} value={sku} change={setSku} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Destaque`} options={featuredOptions} value={featured} change={setFeatured} />
                            </div>
                            <div className="col-6">
                                <InputSelect label={`Novidade`} options={featuredOptions} value={recent} change={setRecent} />
                            </div>
                        </div>

                        <Input label={`Nome do produto`} value={name} change={setName} />
                        <div className="row">
                            <div className="col-6">
                                <InputSelect label={`Selo`} options={createOptions(tags, 'text', 'id', true)} value={tag} change={setTag} null />
                            </div>
                            <div className="col-6">
                                <Input 
                                    label={`Quant. mínima`}
                                    value={minQuantity}
                                    change={setMinQuantity}
                                    mask={`convertToInt`}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="col-6">                        
                        <h2 className="section-title">Descrição para os orçamentos</h2>
                        <InputEditor value={techDescription ?? ''} change={setTechDescription} />
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <h2 className="section-title">Descrição para o site</h2>
                        <InputEditor value={description} change={setDescription} />
                    </div>
                    <div className="col-6">
                        <h2 className="section-title">Categorias</h2>
                        <InputCheckboxesSublevels
                            options={categories}
                            selecteds={selectedCategories}
                            setSelecteds={setSelectedCategories}
                        />
                    </div>
                </div>
            </>
            }
        </div>
    );
}

const VariationsTab = ({product, sku, variations, setVariations}) => {
    const [gettingCompanies, setGettingCompanies] = useState(true);
    const [gettingColorGroups, setGettingColorGroups] = useState(true);

    const [firstLoading, setFirstLoading] = useState(true);
    const [colorGroups, setColorGroups] = useState([]);
    const [colors, setColors] = useState([]);
    const [subproductToChooseImage, setSubproductToChooseImage] = useState(null);

    const [companies, setCompanies] = useState([]);

    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);

    useEffect(() => {
        getColorGroups();
        getCompanies();
    }, [product]);

    useEffect(() => {
        if(colors.length > 0 && companies.length){
            getSubproducts();
        }
    }, [colors, companies]);

    useEffect(() => {
        if(!firstLoading) getVariations();
    }, [selectedColors, selectedCompanies]);

    const getSubproducts = () => {
        if(product.subproducts.length === 0){
            setFirstLoading(false);
            return;
        }
        
        let toVariations = [];

        product.subproducts.map(subproduct => {
            let toPriceVariations = [];

            subproduct.prices.map(price => {
                toPriceVariations.push({
                    company_id: price.company_id,
                    company_price_sku: price.company_price_sku
                });
            });

            
            toVariations.push({
                status: subproduct.status,
                sku: subproduct.sku,
                color_id: subproduct.color_id,
                product_id: subproduct.product_id,
                product_image_id: subproduct.product_image_id,
                product_image: subproduct.image,
                price_variation: toPriceVariations
            });
        });
        setVariations([...toVariations]);
    }

    useEffect(() => {
        if(firstLoading){
            getColorsCompanies();
        }
    }, [variations]);

    const getColorsCompanies = () => {
        if(variations.length === 0) return;

        let toSelectedColors = [];
        let toSelectedCompanies = [];

        variations.map(variation => {
            if(!toSelectedColors.includes(variation.color_id)){
                toSelectedColors.push(variation.color_id);
            }

            variation.price_variation.map(priceVariation => {
                if(!toSelectedCompanies.includes(priceVariation.company_id)){
                    toSelectedCompanies.push(priceVariation.company_id);
                }
            });
        });

        setSelectedColors(toSelectedColors);
        setSelectedCompanies(toSelectedCompanies);
        setFirstLoading(false);
    }

    const getColorGroups = () => {
        api.get(`/group-color`).then(res => {
            setColorGroups(res.data.resources);
            getColors(res.data.resources);
            setGettingColorGroups(false);
        });
    }

    const getColors = groups => {
        let toColors = [];
        groups.map(group => {
            group.colors.map(color => toColors.push(color));
        });
        setColors(toColors);
    }

    const getCompanies = () => {
        api.get(`/company?filters[0][type][contain]=product`).then(res => {
            setCompanies(res.data.resources);
            setGettingCompanies(false);
        });
    }

    const checkPriceVariations = priceVariations => {
        let toPriceVariations = [];

        // adiciona as variações antigas e remove as que não existem mais
        priceVariations.map(priceVariation => {
            if(selectedCompanies.includes(priceVariation.company_id)){
                toPriceVariations.push(priceVariation);
            }
        });

        // adiciona as novas variações
        selectedCompanies.map(selectedCompany => {
            let currentPriceVariation = toPriceVariations.filter(pv => selectedCompany.includes(pv.company_id));
            if(currentPriceVariation.length === 0){
                toPriceVariations.push({
                    company_id: selectedCompany,
                    company_price_sku: null
                });
            }
        });

        return toPriceVariations;
    }

    const getVariations = () => {
        let toVariations = [];
        let priceVariations = [];

        // current
        // variation.price_variation = checkPriceVariations(variation.price_variation);


        selectedColors.map(selectedColor => {
            let color = colors.filter(c => c.id === selectedColor)[0];
            let currentVariation = variations.filter(v => v.color_id === selectedColor);

            if(currentVariation.length > 0){
                toVariations.push({
                    ...currentVariation[0],
                    price_variation: checkPriceVariations(currentVariation[0].price_variation)
                });
                
            } else {
                toVariations.push({
                    status: true,
                    sku: `${sku}-${color.sku}`,
                    color_id: color.id,
                    product_id: product.id,
                    product_image_id: null,
                    product_image: null,
                    price_variation: checkPriceVariations([])
                });
            }
        });
        setVariations(toVariations);
    }

    const handleColorChange = (value, index) => {
        const toVariations = [...variations];
        toVariations[index].color_id = value;
        setVariations(toVariations);
    }

    const handleSkuChange = (value, index) => {
        const toVariations = [...variations];
        toVariations[index].sku = value;
        setVariations(toVariations);
    }

    const handleStatusChange = (value, index) => {
        const toVariations = [...variations];
        toVariations[index].status = value;
        setVariations(toVariations);
    }

    const handleCompanySkuChange = (value, index, indexPrice) => {
        const toVariations = [...variations];
        toVariations[index].price_variation[indexPrice].company_price_sku = value;
        setVariations(toVariations);
    }

    const handleImageChange = image => {
        let toVariations = [...variations];
        let current = toVariations[subproductToChooseImage].product_image_id;
        if(current !== image.id){
            toVariations[subproductToChooseImage].product_image_id = image.id;
            toVariations[subproductToChooseImage].product_image = image;
        } else {
            toVariations[subproductToChooseImage].product_image_id = null;
            toVariations[subproductToChooseImage].product_image = null;
        }
        setVariations(toVariations);
    }
    
    return (
        <>
            {subproductToChooseImage !== null &&
                <Modal
                    title={`Escolher imagem`}
                    show={true}
                    close={() => setSubproductToChooseImage(null)}
                >
                    <div className="section subproduct-images">
                        {product.images.map(image => (
                            <button 
                                onClick={() => handleImageChange(image)} 
                                className={variations[subproductToChooseImage].product_image_id === image.id ? 'active' : ''}
                            >
                                <img src={image.image_url.thumbnail} />
                            </button>
                        ))}
                    </div>
                </Modal>
            }
            {(gettingColorGroups || gettingCompanies) &&
                <p>Carregando...</p>
            }
            {!gettingColorGroups && !gettingCompanies &&
            <div>
                <div className="row">
                    <div className="col-6">
                        <h2 className="section-title">Selecione as cores</h2>
                        <InputColors colorGroups={colorGroups} selecteds={selectedColors} setSelecteds={setSelectedColors} />
                    </div>
                    <div className="col-6">
                        <h2 className="section-title">Fornecedores</h2>
                        <InputCheckboxes 
                            options={createOptions(companies, 'trade_name', 'id')}
                            selecteds={selectedCompanies}
                            setSelecteds={setSelectedCompanies}
                        />
                    </div>
                </div>
                <h2 className="section-title">Detalhes das variações</h2>
                {variations.map((variation, index) => (            
                    <div key={index} className="row">
                        <div className="col-1">
                            <button 
                                className="btn-change-image"
                                onClick={() => setSubproductToChooseImage(index)}
                            >
                                {variation.product_image !== null &&
                                    <img src={variation.product_image.image_url.thumbnail} />
                                }
                                {variation.product_image === null &&
                                    <img src={ require(`../../assets/images/svgs/image.svg`).default} />
                                }
                                <span>Escolher</span>
                            </button>
                        </div>
                        <div className="col">
                            <InputSelect label={`Cor da variação`}
                                options={createOptions(colors, 'name', 'id')}
                                value={variation.color_id}
                                isDisabled={true}
                                // change={value => handleColorChange(value, index)}
                            />
                        </div>
                        <div className="col">
                            <Input label={`SKU`} value={variation.sku} change={value => handleSkuChange(value, index)} />
                        </div>
                        {variation.price_variation.map((priceVariation, indexPrice) => {
                            const company = companies.filter(c => c.id === priceVariation.company_id)[0];
                            if(company !== undefined){
                                return (
                                    <div key={indexPrice} className="col">
                                        <Input 
                                            label={`SKU ${company.trade_name}`}
                                            value={variation.price_variation[indexPrice].company_price_sku}
                                            change={value => handleCompanySkuChange(value, index, indexPrice)}
                                        />
                                        {/* <InputSelect 
                                            label={`SKU ${company.trade_name}`}
                                            options={createOptions(company.prices, 'sku', 'sku', true)} 
                                            change={value => handleCompanySkuChange(value, index, indexPrice)}
                                            value={variation.price_variation[indexPrice].company_price_sku}
                                        /> */}
                                    </div>
                                );
                            }
                        })}
                        <div className="col">
                            <InputSelect 
                                label={`Status`}
                                options={statusOptions}
                                value={variation.status}
                                change={value => handleStatusChange(value, index)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            }
        </>
    );
}

const KeyWordsTab = ({product, keywords, setKeywords}) => {
    const [showCreate, setShowCreate] = useState(false);
    const [selecteds, setSelecteds] = useState([]);
    const [keyword, setKeyword] = useState('');

    const inputRef = useRef();

    useEffect(() => {
        if (inputRef.current) {
            console.log(inputRef.current)
          inputRef.current.focus();
        }
    }, [showCreate]);

    useEffect(() => {
        setKeywords(product.keywords);
    }, [product]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    const deleteKeywords = () => {
        let toKeywords = keywords.filter(k => !selecteds.includes(k));
        setKeywords(toKeywords);
    }

    const storeKeyword = () => {
        if(keywords.includes(keyword)){
            toast.info('Palavra-chave já cadastrada');
        } else {
            let toKeywords = [...keywords];
            toKeywords.push(keyword.trim());
            toKeywords.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            setKeywords(toKeywords);
        }
        clean();
        // setShowCreate(false);
    }

    const clean = () => {
        setKeyword('');
        setSelecteds([]);
    }
    
    return (
        <>
            <Modal
                show={showCreate}
                title={`Cadastrar palavra-chave`}
                close={() => setShowCreate(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        svg={`save-white`}
                        text={`Cadastrar`}
                        action={storeKeyword}
                    />  
                }
            >
                <div className="section">
                    <Input 
                        inputRef={inputRef}
                        type={`text`} 
                        label={`Palavra-chave`} 
                        value={keyword} 
                        change={setKeyword}
                        action={storeKeyword}
                    />
                </div>

            </Modal>
            <div className="row">
                <div className="col-6">
                    <PageHeader
                        left={
                            <h2 className="section-title">Palavras-chave</h2>
                        }
                        right={
                            <>
                                <Button type={`primary`} size={`small`} svg={`plus-white`} text={`Cadastrar`} action={() => setShowCreate(true)} /> 
                                <Button type={`secondary`} size={`small`} svg={`delete-small`} text={`Excluir selecionadas`} action={deleteKeywords} />
                            </>
                        }
                    />
                    <InputCheckboxes 
                        options={createOptions(keywords)}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                    />
                </div>
            </div>
        </>
    );
}

const CustomizationsTab = ({selectedCustomizations, setSelectedCustomizations}) => {
    const [customizations, setCustomizations] = useState([]);

    useEffect(() => {
        getCustomizations();
    }, []);

    const getCustomizations = () => {
        api.get(`/customization`).then(res => {
            setCustomizations(res.data.resources);
        })
    }

    return (
        <div className="row">
            <div className="col-6">
                <h2 className="section-title">Gravações</h2>
                <InputCheckboxes 
                    options={createOptions(customizations, 'name', 'id')} 
                    selecteds={selectedCustomizations}
                    setSelecteds={setSelectedCustomizations}
                />
            </div>
        </div>
    );
}

const SeoTab = ({metaTitle, setMetaTitle, metaDescription, setMetaDescription, slug, setSlug}) => {
    return (
        <div className="row">
            <div className="col-6">
            <Input label={`Slug`} value={slug} change={setSlug} />
            <Input label={`Meta title`} value={metaTitle} change={setMetaTitle} />
            <InputTextarea label={`Meta description`} value={metaDescription} change={setMetaDescription} rows={5}/>
            </div>
        </div>
    );
}

export const ProductEdit = () => {
    const { id } = useParams();
    const navigete = useNavigate();

    const [loading, setLoading] = useState(true);

    const [tags, setTags] = useState([]);

    const [product, setProduct] = useState(null);
    const [status, setStatus] = useState(false);
    const [sku, setSku] = useState('');
    const [featured, setFeatured] = useState(false);
    const [recent, setRecent] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [techDescription, setTechDescription] = useState('');
    const [tag, setTag] = useState(null);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [minQuantity, setMinQuantity] = useState(1);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [variations, setVariations] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [selectedCustomizations, setSelectedCustomizations] = useState([]);

    const [origem, setOrigem] = useState(null);
    const [ncm, setNcm] = useState('');
    const [cest, setCest] = useState('');

    const [confirmDelete, setConfirmDelete] = useState(false);


    useEffect(() => {
        getCategories();
        getTags();
        getProduct();
    }, []);

    const getProduct = () => {
        setLoading(true);
        api.get(`/product/${id}`).then(res => {
            let resource = res.data.resource;
            let toSelectedCategories = [];
            let toSelectedCustomizaitons = [];
            resource.categories.map(category => toSelectedCategories.push(category.id));
            resource.customizations.map(customization => toSelectedCustomizaitons.push(customization.id));
            setProduct(resource);
            setSelectedCategories(toSelectedCategories);
            setSelectedCustomizations(toSelectedCustomizaitons);
            setStatus(resource.status);
            setSku(resource.sku);
            setFeatured(resource.featured);
            setRecent(resource.recent);
            setName(resource.name);
            setDescription(resource.description);
            setTechDescription(resource.tech_description);
            setTag(resource.tag_id);
            setMetaTitle(resource.meta_title ?? "");
            setMetaDescription(resource.meta_description ?? "");
            setSlug(resource.slug);
            setMinQuantity(resource.min_quantity);
            setOrigem(resource.origem);
            setNcm(resource.ncm);
            setCest(resource.cest);

            let toVariations = [];

            resource.subproducts.map(subproduct => {
                toVariations.push({
                    ...subproduct,
                    price_variation: subproduct.prices,
                    product_image: null
                });
            });
            setVariations(toVariations);

        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getTags = () => {
        api.get(`/tag`).then(res => {
            setTags(res.data.resources);
        });
    }

    const getCategories = () => {
        api.get(`/category`).then(res => {
            let toCategories = [];

            res.data.resources.map(category => {
                let options = [];

                category.categories.map(subcategory => {
                    options.push({
                        label: subcategory.name,
                        value: subcategory.id
                    })
                });
                toCategories.push({
                    label: category.name,
                    value: category.id,
                    options
                })
            })
            setCategories(toCategories);
        });
    }

    const updateProduct = () => {
        setLoading(true);
        
        api.put(`/product/${id}`, {
            status,
            featured,
            recent,
            name,
            sku,
            description,
            tech_description: techDescription,
            categories: selectedCategories,
            keywords,
            customizations: selectedCustomizations,
            tag_id: tag,
            subproducts: variations,
            meta_title: metaTitle,
            meta_description: metaDescription,
            slug,
            min_quantity: minQuantity,
            origem,
            ncm,
            cest
        }).then(res => {
            toast.success('Produto atualizado com sucesso');
            getProduct();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteProduct = () => {
        setLoading(true);

        api.delete(`/product/${id}`).then(res => {
            navigete(`/product/list`);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <ModalDelete
                show={confirmDelete}
                action={deleteProduct}
                loading={loading}
                close={() => setConfirmDelete(false)}
            />
            <PanelTemplate id="product-edit">
                <Loading show={loading} />
                {!loading && product !== null &&
                    <>
                        <BoxTabs
                            titles={[
                                'Detalhes',
                                'Imagens',
                                'Variações',
                                'Palavras-chave', 
                                'Gravações', 
                                'SEO',
                                'Tributação'
                            ]}
                            contents={[
                                <ProductDetailsTab
                                    loading={loading}
                                    status={status}
                                    setStatus={setStatus}
                                    sku={sku}
                                    setSku={setSku}
                                    featured={featured}
                                    setFeatured={setFeatured}
                                    recent={recent}
                                    setRecent={setRecent}
                                    name={name}
                                    setName={setName}
                                    categories={categories}
                                    setSelectedCategories={setSelectedCategories}
                                    selectedCategories={selectedCategories}
                                    setDescription={setDescription}
                                    description={description}
                                    setTechDescription={setTechDescription}
                                    techDescription={techDescription}
                                    tags={tags}
                                    tag={tag}
                                    setTag={setTag}
                                    minQuantity={minQuantity}
                                    setMinQuantity={setMinQuantity}
                                />,
                                <ProductImagesTab
                                    product={product}
                                />,
                                <VariationsTab 
                                    product={product}
                                    sku={sku}
                                    variations={variations}
                                    setVariations={setVariations}
                                />,
                                <KeyWordsTab
                                    product={product}
                                    keywords={keywords}
                                    setKeywords={setKeywords}
                                />,
                                <CustomizationsTab
                                    product={product}
                                    selectedCustomizations={selectedCustomizations}
                                    setSelectedCustomizations={setSelectedCustomizations}
                                />,
                                <SeoTab
                                    metaTitle={metaTitle}
                                    setMetaTitle={setMetaTitle}
                                    metaDescription={metaDescription}
                                    setMetaDescription={setMetaDescription}
                                    slug={slug}
                                    setSlug={setSlug}
                                />,
                                <ProductTributacao 
                                    origem={origem}
                                    setOrigem={setOrigem}
                                    ncm={ncm}
                                    setNcm={setNcm}
                                    cest={cest}
                                    setCest={setCest}
                                />
                            ]}
                        />
                        <PageFooter spaceBetween={true}>
                            <>
                                {hasPermission('edit_product') &&
                                    <Button
                                    type={`primary`}
                                    size={`small`}
                                    svg={`save-white`}
                                    text={`Salvar alterações`}
                                    action={updateProduct}
                                    loading={loading}
                                    />
                                }
                                {hasPermission('delete_product') &&
                                    <Button
                                    type={`secondary`}
                                    size={`small`}
                                    svg={`delete-small`}
                                    text={`Excluir`}
                                    action={() => setConfirmDelete(true)}
                                    loading={loading}
                                    />
                                }
                            </>
                        </PageFooter>
                    </>
                }
            </PanelTemplate>
        </>
    );
}

export default ProductEdit;